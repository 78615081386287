import { render, staticRenderFns } from "./YoutubeVideo.vue?vue&type=template&id=cdda9568&scoped=true"
import script from "./YoutubeVideo.vue?vue&type=script&lang=js"
export * from "./YoutubeVideo.vue?vue&type=script&lang=js"
import style0 from "./YoutubeVideo.vue?vue&type=style&index=0&id=cdda9568&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdda9568",
  null
  
)

export default component.exports